import * as React from "react";
import PageLayout from "../../PageLayout";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import HowWeCanHelp from "../../components/HowWeCanHelp";
import arc from "./../../images/arc.png";
import cal from "./../../images/cal-contractor.png";
import clean_energy from "./../../images/clean-energy.png";
import woodward from "./../../images/woodward.png";
const Accreditation = () => {
  return (
    <PageLayout
      noButton
      title={"E-P-G's Accreditation"}
      subtitle={"Qualified and experienced technicians"}
      navSize={"h-96"}
      titleStyles={"xl:mt-12 lg:mt-16 md:mt-16 sm:mt-20 xs:mt-16 mt-8"}
    >
      <Helmet>
        <title>
          About Us | EPG Solutions | Powering the Northern Territory
        </title>
        <meta
          name="description"
          content="Trust Philip Schutte and the E-P-G Solutions team to meet your remote power generation and maintenance needs."
        />
      </Helmet>
      <div className={"bg-epg-yellow h-5"} />
      <SectionLayout bgColor={"bg-epg-lightgray"}>
        <p className={"text-center font-exo2 leading-8 text-xl py-5"}>
          E-P-G Solutions is not just experienced, we’re qualified too. Our team
          have the following qualifications as standard.
        </p>
      </SectionLayout>
      <SectionLayout bgColor={"bg-epg-white"}>
        <div className={"bg-epg-white pt-5"}>
          <h4
            className={"text-3xl lg:text-4xl text-epg-gray font-semibold font-exo2 mt-4"}
          >
            General Accreditations
          </h4>
          <ul
            className={
              "list-disc grid lg:grid-cols-2 xl:gap-16 gap-4 p-4 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"
            }
          >
            <div>
              <li>Construction White Card</li>
              <li>Electrical Workers Licence</li>
              <li>Electrical Contractor Licence</li>
              <li>Master Cabler</li>
              <li>Clean Energy Council Accredited Designer and Installer</li>
            </div>
            <div>
              <li>Refrigerant Handling Licence</li>
              <li>Type B Gasfitter Licence</li>
              <li>High Risk Work Licence</li>
              <li>Working with Children</li>
            </div>
          </ul>
        </div>
        <div className={"bg-epg-white pt-5"}>
          <h4
            className={"text-3xl lg:text-4xl text-epg-gray font-semibold font-exo2 mt-4"}
          >
            CAL Accreditation
          </h4>
          <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
            E-P-G Solutions holds CAL Accreditation in the following areas:
          </p>
          <ul className={"list-disc grid lg:grid-cols-2 p-4 xl:gap-16 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
            <div>
              <li>Electrical & Automatic Control Systems.</li>
              <li>Power Generation Systems HV.</li>
              <li>Power Generation Systems LV.</li>
            </div>
           <div>
             <li>Power Generation Systems Renewable Design.</li>
             <li>Power Generation Systems Renewable Install.</li>
             <li>LP Gas.</li>
           </div>
          </ul>
        </div>
        <div className={"items-center grid md:grid-cols-4 gap-4 lg:pt-12 lg:pb-6 p-5 bg-epg-white"}>
          <img className={"mb-4 "} src={arc} alt="BigCo Inc. logo" />
          <img className={"mb-4"} src={cal} alt="BigCo Inc. logo" />
          <img className={"mb-4"} src={clean_energy} alt="BigCo Inc. logo" />
          <img className={"w-full px-5 mb-4"} src={woodward} alt="BigCo Inc. logo" />
        </div>
        <div className={"bg-epg-white py-5"}>
          <h3 className={"text-3xl lg:text-4xl text-epg-gray font-semibold font-exo2"}>
            Commitment to quality, safety and the environment
          </h3>
          <p className={"font-exo2 text-epg-gray text-md leading-7 lg:leading-8 lg:text-lg  mt-8"}>
            E-P-G Solutions is committed to providing a high-quality service in
            a safe environment. We are compliant with international standards
            (based on ISO14001 and 9002) as well as work health safety
            requirements. To us, safety is non-negotiable.
          </p>
          <p className={"font-exo2 text-epg-gray text-md leading-7 lg:leading-8 lg:text-lg mt-4 mb-4"}>
            As well as upholding rigorous environmental standards, we ensure all
            our operations are as sustainable as possible. This includes correct
            waste disposal and recycling, both on-site and in workshop
            operations.
          </p>
        </div>
      </SectionLayout>


      <HowWeCanHelp />
    </PageLayout>
  );
};

export default Accreditation;
